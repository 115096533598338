/**
 * Source Action
 *
 * Get data or validate
 */

import { def } from 'config/const';
import api from 'helpers/api';
import * as _ from 'lodash';

const sourceAction = {
  countryList: (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.post('order_portal/get_countries')
        dispatch({ type: 'GET_COUNTRY', payload: res.data });
        resolve(res.data);
      } catch (error) {
        console.log(error);
        reject(false)
      }
    })
  },
  counterList: (dispatch, customer_type) => {
    return new Promise(async (resolve, reject) => {
      try {
        const {data} = await api.post('order_portal/getCounterList', {customer_type})

        if(!!data) {
          dispatch({ type: 'GET_COUNTER_LIST', payload: data });
          resolve(data);
        } else {
          resolve(false)
        }
      } catch (error) {
        reject(false)
      }
    })
  },
  publicHoliday: (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        const {data} = await api.post('order_portal/getPublicHoliday')

        if(!!data) {
          dispatch({ type: 'GET_PUBLIC_HOLIDAY', payload: data.response.data });
          resolve(true);
        } else {
          resolve(false)
        }
      } catch (error) {
        reject(false)
      }
    })
  },
  provider: (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        const {data} = await api.post('order_portal/get_provider')

        if(!!data) {
          dispatch({ type: 'GET_PROVIDER', payload: data.response });
          resolve(true);
        } else {
          resolve(false)
        }
      } catch (error) {
        reject(false)
      }
    })
  },
  addressByVillage: (dispatch, village_id) => {
    return new Promise(async (resolve, reject) => {
      try {
        if(!!village_id) {
          const {data} = await api.post('order_portal/get_address_by_village', {village_id});

          if(!!data) {
            dispatch({ type: 'GET_ADDRESS_DETAIL' });
            resolve(data);
          } else {
            resolve(null)
          }
        } else {
          resolve(null);
        }
      } catch (error) {
        reject(false)
      }
    })
  },
  // customerClassType: (dispatch) => {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       const {data} = await api.post('order_portal/getCustomerClassType')

  //       if(!!data) {
  //         dispatch({ type: 'GET_CUSTOMER_CLASS_TYPE', payload: data });
  //         resolve(true);
  //       } else {
  //         resolve(false)
  //       }
  //     } catch (error) {
  //       reject(false)
  //     }
  //   })
  // },
  billingCycle: (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        const {data} = await api.post('order_portal/getCycleSelectionList')

        if(!!data) {
          dispatch({ type: 'GET_BILLING_CYCLE', payload: data });
          resolve(true);
        } else {
          resolve(false)
        }
      } catch (error) {
        reject(false)
      }
    })
  },
  msisdn: (dispatch, params) => {
    return new Promise(async (resolve, reject) => {
      try {
        // Old Api MSISDN
        let body = {
          ...params
        }
        
        // New Api MSISDN
        /* let body = {
          ...params,
          newOP: true,
          numberStatus: 4,
        } */
        const {data} = await api.post('order_portal/search_number', body)
        dispatch({ type: 'GET_MSISDN' });
        resolve(data);
      } catch (error) {
        reject(false)
      }
    })
  },
  orderCatalog: (dispatch, params) => {
    return new Promise(async (resolve, reject) => {
      try {
        const {data} = await api.post('order_portal/get_order_catalog', params)
        dispatch({ type: 'GET_ORDER_CATALOG' });
        resolve(data);
      } catch (error) {
        reject(false)
      }
    })
  },
  offerings: (dispatch, orderData) => {
    return new Promise(async (resolve, reject) => {
      try {
        const previousProvider = orderData.previousProvider
        const customerInfo = orderData.customerInfo
        const orderType = orderData.orderType
        const params = {
          order_type: orderType,
          customer_type: customerInfo.customerType,
          internal_portin: def.internalPortIn.includes(Number(previousProvider)),
          sales_channel: 7, // is order_portal
          network: customerInfo.network
        }
        const {data} = await api.post('order_portal/get_offerings_list', params)

        if(!!data.length) {
          let offerings = data;
              offerings = _.orderBy(offerings, [(item) => {
                const members = _.get(item, 'member');
                item['member'] = _.orderBy(members, [(member) => {
                  const devices = _.get(member, 'devices');
                  if(!_.isEmpty(devices)) {
                    _.forIn(devices, (deviceByCategory, category) => 
                      _.forIn(deviceByCategory, (deviceByType, type) => 
                        devices[category][type] = _.orderBy(deviceByType, 'bundle_item_id', 'asc')
                      )
                    )
                  }

                  return parseFloat(member.price.slice(1));
                }], 'asc')
                return item['name'];
              }], 'asc')
          dispatch({ type: 'GET_OFFERINGS', payload: offerings });
          resolve(true);
        } else {
          resolve(false)
        }
      } catch (error) {
        console.log(error)
        reject(false)
      }
    })
  },
  resetOfferings: (dispatch) => {
    dispatch({ type: 'RESET_OFFERINGS' });
  },
  dependencyCatalog: (dispatch, orderData, bundle_id, item_id = null) => {
    return new Promise(async (resolve, reject) => {
      try {
        const previousProvider = orderData.previousProvider
        const customerInfo = orderData.customerInfo
        const orderType = orderData.orderType
        const nationality = customerInfo.customerDetail.nationality
        const params = {
          item_id,
          bundle_id,
          customer_type: customerInfo.customerType,
          promo_tag: orderType === "Port-IN" ? 509 : 510,
          internal_portin: def.internalPortIn.includes(Number(previousProvider)),
          order_type: orderType,
          nationality: nationality
        }

        const {data} = await api.post('order_portal/get_dependency_catalog', params)
        dispatch({ type: 'GET_DEPENDENCY_CATALOG' })
        resolve(data)
      } catch (error) {
        reject(false)
      }
    })
  },
  promoBundle: (dispatch, orderData, bundleId, memberId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const previousProvider = orderData.previousProvider
        const orderType = orderData.orderType
        const params = {
          bundle_id: bundleId,
          member_id: memberId,
          order_type: orderType,
          internal_portin: def.internalPortIn.includes(Number(previousProvider))
        }
        const {data} = await api.post('order_portal/get_promo_bundle', params)

        dispatch({ type: 'GET_PROMO_BUNDLE' })
        resolve(data)
      } catch (error) {
        reject(false)
      }
    })
  },
  stockItem: (dispatch, devices, customerInfo)  => {
    return new Promise(async (resolve, reject) => {
      try {
        const isForceWarehouse = def.forceWarehouse.includes(customerInfo.customerType)
        let params = {
          sku_code: [],
          warehouse_id: isForceWarehouse ? def.forceWarehouseID : null
        }
        devices.map(obj => params.sku_code = _.union(params.sku_code, obj.sku_code))
        params['store_id'] = !!customerInfo.deliveryPickup && customerInfo.deliveryPickup.delivery_type === "1"
          ? Number(customerInfo.deliveryPickup.store_id)
          : 10
        
        const {data} = await api.post('order_portal/get_item_stock', params)
        dispatch({ type: 'GET_STOCK_ITEM' })
        resolve(data)
      } catch (error) {
        reject(false)
      }
    })
  },
  validateReferral: (dispatch, msisdnServiceLine, customerInfo, plan)  => {
    return new Promise(async (resolve, reject) => {
      try {
        const params = {
          msisdn_service_line: msisdnServiceLine,
          customer_type: customerInfo.customerType,
          bundle_id : plan[0].id
        }
        const {data} = await api.post('order_portal/validate_referral', params)
        dispatch({ type: 'VALIDATE_REFERRAL' })
        resolve(data)
      } catch (error) {
        reject(false)
      }
    })
  }
};

export default sourceAction;